/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'back': {
    width: 17,
    height: 17,
    viewBox: '0 0 17 17',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M4.702 4.734a.533.533 0 00-.754.007L1.145 7.594a.533.533 0 00.006.754l2.853 2.804a.533.533 0 10.748-.76L2.817 8.49l12.046-.105a.534.534 0 00-.01-1.067l-12.045.105L4.709 5.49a.534.534 0 00-.007-.755z" _fill="#000"/>'
  }
})
